<template>
  <div class="container">
    <div class="commerces__wrapper screen__wrapper">
      <Card v-for="commerce in commerces" :key="commerce.id" :obj="commerce" @click.native="info(commerce)" />
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="loadObj"></infinite-loading>
  </div>
</template>

<script>
import Card from '../Card'
import InfiniteLoading from 'vue-infinite-loading'
import Info from '../Info'

export default {
  name: 'Commerce',
  components: {
    Card,
    InfiniteLoading
  },
  data: () => ({
    isLoading: false
  }),
  computed: {
    commerces() {
      return this.$store.getters['objects/commerce/commerce']
    },
    hasMoreResults() {
      return this.commerces.length < this.totalCount && this.page > 1
    },
    totalCount() {
      return this.$store.getters['objects/commerce/pagination'].total
    },
    page() {
      return this.$store.getters['objects/commerce/page']
    }
  },
  methods: {
    async loadObj() {
      this.isLoading = true
      const page = this.page
      try {
        await this.$store.dispatch('objects/commerce/fetch')
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    },
    info(obj) {
      this.$modal.display(Info, {info: obj}, {name: 'InfoObject'})
    }
  },
  // beforeDestroy() {
  //   if (
  //     Object.keys(this.$store.getters.sortParams).length !== 0 ||
  //     Object.keys(this.$store.getters.filterParams).length !== 0
  //   ) {
  //     this.$store.commit('sortParams', {})
  //     this.$store.commit('filterParams', {})
  //     this.$store.commit('resetCommerces')
  //     this.loadObj()
  //   }
  //   this.$store.commit('filterParamsAll', {})
  //   this.$store.commit('filterParamsObjectsActive', 0)
  // },
}
</script>